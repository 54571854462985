export const questions = [
  {
    id: 1,
    question: "Do I need prior skills and knowledge to apply for ACM's recruitments?",
    answer: 'Not at all, Everyone is welcome to apply in any domain. All you need is a thirst for knowledge and a drive to grow and develop.',
  },
  {
    id: 2,
    question: 'Are the recruitments only for 1st year students?',
    answer: 'Yes the recruitments are only for 2027 Batch freshers.',
  },
  {
    id: 3,
    question: 'What is the mode of recruitment for ACM?',
    answer: 'There are 3 rounds in the recruitment process: a) Fill up the recruitment form b) Appear for the recruitment quiz c) Show up for the personal interview',
  },
  {
    id: 4,
    question: 'What are the various domains in ACM?',
    answer: 'We have various departments in ACM like Web Development, App Development, Academic Dept, ML/DL, Designing, Marketing, Video Editing, etc.',
  },
  {
    id: 5,
    question: 'What is ACM?',
    answer: 'We are a group of enthusiastic people who explore around and learn new things :)',
  },
  {
    id: 6,
    question: 'What type of questions will be asked in quiz?',
    answer: 'Basic logic based and mental ability questions.',
  },
];
